<template>
  <div>
    <input v-model="barcode" placeholder="Enter Barcode" />
    <button @click="searchProduct">Search</button>

    <button @click="getNextProduct">GET NEXT PRODUCT</button>
    <button @click="skipProduct">SKIP PRODUCT</button>
    <div v-if="loading">Loading...</div>

    <div v-if="error">{{ error }}</div> 
    <div v-if="product">
      <h2>{{ product.name }}</h2>
      <!---<p><strong>Description:</strong> {{ product.description }}</p>
      <p><strong>Category:</strong> {{ product.category }}</p>
      <p><strong>Brand:</strong> {{ product.brand }}</p>
      <div v-if="!product.ingredients">
        <p><strong>Ingredients:</strong> No ingredients found for this product.</p>
      </div>

      <div v-else>
        <p><strong>Ingredients:</strong> {{ product.ingredients }}</p>
      </div>
      -->
      <img :src="product.image_url" :alt="product.name" width="200" />

      

      <textarea v-model="newIngredients" placeholder="Update Ingredients"></textarea>
      <a :href="generateAmazonSearchUrl(product.name)" target="_blank" class="btn btn-info">AMZ</a>
      <div>
        <p><strong>Affiliate URL:</strong> {{ product.affiliate_url ? product.affiliate_url : 'No affiliate URL found' }}</p>
        <input v-model="newAffiliateUrl" placeholder="Update Affiliate URL" />
      </div>
      <div>
  <p><strong>Image URL:</strong> {{ product.image_url ? product.image_url : 'No image URL found' }}</p>
  <input v-model="newImageUrl" placeholder="Update Image URL" />
  <img :src="newImageUrl" alt="New Image Preview" class="img-fluid mt-2" width="200" v-if="newImageUrl" />
</div>
        <button @click="submitIngredients">Update</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
       barcode: '',
    product: null,
    newIngredients: '',
    newAffiliateUrl: '',
    newImageUrl: '', // Add this line
    loading: false,
    error: ''
    };
  },
  methods: {
    generateAmazonSearchUrl(foodName) {
      const baseAmazonUrl = "https://www.amazon.com/s?k=";
      if (this.product && this.product.name) {
        return baseAmazonUrl + encodeURIComponent(this.product.name);
      }
      return '';
    },
    skipProduct() {
      const _this = this;
      fetch(`/admin/products/skip/${_this.product.id}`, {
        method: 'POST',
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        this.product = data.product;
      });
    },
  getNextProduct() {
    this.product = null;
    this.barcode = '';
    this.newIngredients = '';
    this.newAffiliateUrl = '';
    this.newImageUrl = '';
    this.error = '';
    this.loading = false;
    const _this = this;
    fetch('/admin/products/next')
      .then(response => response.json())
      .then(data => {
        console.log(data);
        _this.product = data.product;
      });
  },
    async searchProduct() {
    console.log("SEARCHING")
      if (!this.barcode) {
        this.error = "Please enter a barcode.";
        return;
      }

      this.loading = true;
      this.newIngredients = null;
      this.newAffiliateUrl = null;
      this.error = '';
      this.product = null;

      try {
        const response = await fetch(`search/${this.barcode}`);
        const data = await response.json();

        if (data.success) {
          this.product = data.product;
          this.newImageUrl = data.product.image_url;
        } else {
          this.error = "Product not found.";
        }
      } catch (error) {
        this.error = "An error occurred while fetching the product.";
      } finally {
        this.loading = false;
      }
    },
    async submitIngredients() {
  try {
    // Send the POST request to update the product
    const response = await fetch('/admin/ingredients', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        food_id: this.product.id,
        ingredients: this.newIngredients || null, // Allow ingredients to be null
        affiliate_url: this.newAffiliateUrl || null,
        image_url: this.newImageUrl || null
      })
    });

    const data = await response.json();

    if (data.success) {
      alert(data.message); // Notify the user of success
      this.product.ingredients = this.newIngredients; // Update the product data locally
      this.product.affiliate_url = this.newAffiliateUrl;
      this.product.image_url = this.newImageUrl; // Update the image URL locally
    } else {
      this.error = "Failed to update product.";
    }
  } catch (error) {
    console.error("Error updating product:", error);
    this.error = "An error occurred while updating the product.";
  }
}
  }
};
</script>